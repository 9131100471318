import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import Cookie from 'js-cookie'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ApolloQueryResult } from 'apollo-client'
import { CSRF_TOKEN } from '../constants'
import { AppSpinner } from '../components/AppSpinner'
import {
  GetMyPortalUser,
  GetMyPortalUser_me,
} from './__generated__/GetMyPortalUser'
import { PortalLogin, PortalLoginVariables } from './__generated__/PortalLogin'
import { ADDRESS_FRAGMENT } from '../schema/fragments'
import i18n from '../i18n'
import { isDistributor } from '../components/lib'
import { navigate } from '@reach/router'
import { ORDERS_INDEX_PATH } from '../routes'

// TODO [#633]: Once you have a known type/interface for the user object, please update
// this file and replace all 'GetMyUser_person' occurances, with the User type.
interface AuthContext {
  // eslint-disable-next-line @typescript-eslint/camelcase
  user: GetMyPortalUser_me | null
  login: (
    email: string,
    password: string
  ) => Promise<ApolloQueryResult<GetMyPortalUser> | null>
  logout: () => void
  loading: boolean
}

const AuthContext = React.createContext<AuthContext | null>(null)

const GET_ME = gql`
  query GetMyPortalUser {
    me {
      id
      firstName
      lastName
      name
      email
      profession
      otherProfession
      npi
      preferredShippingAddressId
      businessType
      canCreateOrders
      canEditOrders
      preferredShippingAddress {
        ...AddressFragment
        defaultAdminSet {
          id
        }
      }
      shippingAddresses {
        ...AddressFragment
        defaultAdminSet {
          id
        }
      }
      teamMember {
        id
      }
      customer {
        id
        name
        region {
          code
        }
        country
        purchaseOrder {
          id
          reference
          startDate
          endDate
        }
        salesRep {
          id
          name
          email
        }
        regionalCustomerCareRep {
          id
          name
          email
        }
        sapBillingDetail {
          id
          poRequired
        }
      }
      loginCount
      hasUpdatedDetails
      countryConfiguration {
        id
        privacyNoticeLink
        termsOfUseLink
        copyrightNoticeLink
        countryCode
        portalRegistrationLink
      }
      defaultOrderForm
    }
  }
  ${ADDRESS_FRAGMENT}
`

const LOGIN = gql`
  mutation PortalLogin($input: LoginInput!) {
    portalLogin(input: $input) {
      id
      firstName
      lastName
      name
      email
    }
  }
`

export const AuthProvider: React.FC = props => {
  // code for pre-loading the user's information if we have their token in localStorage goes here
  const { client, data, loading, refetch } = useQuery<GetMyPortalUser>(GET_ME)
  const [loginMutation, { loading: loginLoading }] = useMutation<
    PortalLogin,
    PortalLoginVariables
  >(LOGIN)

  useEffect(() => {
    // Function to map country code to language/locale
    const mapCountryCodeToLocale = (countryCode: string) => {
      // Add your mapping logic here
      switch (countryCode) {
        case 'FR':
          return 'fr' // France
        default:
          return 'en' // Default to English (United States)
      }
    }

    // Retrieve the user's country code
    const userCountryCode = data?.me?.customer.country

    if (userCountryCode) {
      // Map the country code to the corresponding language/locale
      const userLocale = mapCountryCodeToLocale(userCountryCode)

      // Set the language/locale in your i18n library
      i18n.changeLanguage(userLocale)
    }
  }, [data?.me])

  const login = async (
    email: string,
    password: string
  ): Promise<ApolloQueryResult<GetMyPortalUser> | null> => {
    // make a login request & then refetch the 'me' query
    const result = await loginMutation({
      variables: { input: { email, password } },
    })
    if (!result.data || !result.data.portalLogin) return null

    const refetchResult = await refetch()

    if (
      refetchResult &&
      refetchResult.data?.me &&
      isDistributor(refetchResult.data?.me)
    ) {
      const currentPath = window.location.pathname
      if (currentPath === '/') {
        navigate(ORDERS_INDEX_PATH)
      }
    }
    return refetchResult
  }

  const logout = async (): Promise<void> => {
    try {
      //Set language on basic of browser detection
      i18n.changeLanguage(i18n.options.lng)

      // Clear the CSRF token cookie
      Cookie.set(CSRF_TOKEN, '')
      // Clear the apollo store, and then refetch the user's data (which will fail)
      await client.resetStore()
      refetch()
    } catch (error) {
      //@ts-ignore
      console.log(error.message)
    }
  }

  // 🚨 this is the important bit.
  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a user token and if we do, then we render a spinner
  // while we go retrieve that user's information.
  // if (error) return <h2>{`${error}`}</h2>

  if (loading) return <AppSpinner />

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  return (
    <AuthContext.Provider
      value={{
        user: data ? data.me : null,
        login,
        logout,
        loading: loginLoading,
      }}
      {...props}
    />
  )
}

export const useAuth = (): AuthContext | null => React.useContext(AuthContext)
