import gql from 'graphql-tag'
import {
  POSTAL_ADDRESS_FRAGMENT,
  TREATMENT_FRAGMENT,
  ACCESSORY_FRAGMENT,
} from '../fragments'

export const ORDERS_INDEX_QUERY = gql`
  query OrdersIndex(
    $state: OrderStateEnum
    $page: Int
    $sort: SortInput
    $limit: Int
    $search: String
    $status: OrderPortalStatusEnum
    $customerId: ID
  ) {
    orders(
      state: $state
      page: $page
      sort: $sort
      limit: $limit
      search: $search
      status: $status
      customerId: $customerId
    ) {
      totalPages
      items {
        patientRef
        status
        state
        treatmentDate
        treatmentTime
        orderNumber
        customerName
        customerPortalOrder
        doses {
          dosage
          quantity
        }
        accessories {
          quantity
          delivery
        }
        actualShipDate
        shipDate
        reservedAt
      }
    }
  }
`

export const ORDERS_SHOW_QUERY = gql`
  query OrderShow($id: ID!) {
    order(id: $id) {
      id
      orderNumber
      address {
        ...PostalAddressFragment
      }
      accessories {
        ...Accessory
      }
      acceptedAt
      reservedAt
      actualShipDate
      cancellationReason {
        __typename
        id
        code
        customerFacingDescription
        type
      }
      carrier
      createdAt
      createdByType
      createdByName
      confirmedByName
      confirmedByType
      shippedByName
      cancellationRequestedByName
      cancellationRequestedByType
      cancellationRequestedAt
      cancelledAt
      cancelledByName
      cancelledByType
      creditedAt
      creditType
      customerName
      doses {
        dosage
      }
      financeStatus
      invoiceNumber
      invoiceTotalAmount
      invoiceCurrencyCode
      patientRef
      poNumber
      shippingLink
      poNumberAfterShipped
      rebillCurrencyCode
      rebillTotalAmount
      sapCreditMemoNumber
      sapRebillCurrencyCode
      sapRebillTotalAmount
      sapSalesOrderNumber
      shipDate
      status
      customerPortalOrder
      state
      treatments {
        ...Treatment
      }
      treatmentDate
      treatmentTime
      waybillNumber
      treatingPhysicianId
      treatingPhysicianName
      deliveryDate
      dockDate
      usingMnxReservedSurplusInventory
      treatmentIntent
      distributorId
    }
  }
  ${ACCESSORY_FRAGMENT}
  ${POSTAL_ADDRESS_FRAGMENT}
  ${TREATMENT_FRAGMENT}
`

export const PORTAL_VIAL_SEARCH = gql`
  query PortalVialSearch(
    $orderDate: String!
    $orderTime: String!
    $treatmentDate: String!
    $treatmentTime: String!
    $desiredActivitiesAtTreatment: [Float!]!
    $mnxRushOrder: Boolean
  ) {
    portalVialSearch(
      orderDate: $orderDate
      orderTime: $orderTime
      treatmentDate: $treatmentDate
      treatmentTime: $treatmentTime
      desiredActivitiesAtTreatment: $desiredActivitiesAtTreatment
      mnxRushOrder: $mnxRushOrder
    ) {
      vials
      __typename
    }
  }
`
